import React, { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import {
  Flex,
  Loader,
  Button,
  View,
  Text,
  Image,
  Heading,
  Link,
} from "@aws-amplify/ui-react";

import logo from "../assets/images/logo.png";
import "../components/styling/reconViewCompany.css";
import { BiArrowToTop } from "react-icons/bi";

import CompanyInformation from "../components/CompanyInformation";
import CyberCenterCard from "../components/CyberCenterCard";

import EnterDomainField from "../components/EnterDomainField";

import { useCyberScoreCombinedQuery } from "../hooks/useCyberScoreCombinedQuery";

// import { testPeterGen } from "../hooks/usePeterGenerator";

import P1 from "../components/CTA/screens/P1";
// import P11 from "../components/CTA/screens/P11";
import { useData } from "../components/CTA/DataContext";
// import generateSessionId from "../hooks/util/generateSessionId";
import { useBranding } from "../features/branding/hooks";
import ProfilePictures from "../features/profiles/components/ProfilePictures";
import ViewIPCard from "../components/ViewIPCard";
import DarkwebEmailBreachesInfo from "../components/DarkwebEmailBreachInfo";
import EmailSpoofingProtectionInfo from "../components/EmailSpoofingProtectionInfo";
import SecureEmailGatewayInfo from "../components/SecureEmailGatewayInfo";
import WebsiteSecuritySettingsInfo from "../components/WebsiteSecuritySettingsInfo";
import WordPressPluginSecurityInfo from "../components/WordPressPluginSecurityInfo";
import WebsiteEncryptionInfo from "../components/WebsiteEncryptionInfo";
import WebsiteFirewallInfo from "../components/WebsiteFirewallInfo";
import { Marquee } from "../components/Marquee";
import { FaMedal } from "react-icons/fa6";

import { Modal } from "../components/Modal";
import { ClaimScoreView } from "../features/score-claim/components";
import { useCallToActionContext } from "../features/call-to-actions/contexts/CallToActionContext";
import { useViewAccess } from "../features/call-to-actions/hooks";

// tried to add these to the interface folder/file but too many issues
export interface PersonalizedRiskItem {
  Email: string;
  BreachDate: string;
  BreachName: string;
}

export interface CyberScoreSimpleType {
  [key: string]: number;
}

export interface CyberScoreDetailedType {
  [key: string]: any[] | string | number;
}

export interface ThemeAttributes {
  colors: Record<string, string>;
  icons: Record<string, JSX.Element>;
  subtitles: Record<string, string>;
}

export default function HackerViewCompany() {
  /* *********************** Hooks & State ************************* */

  const {
    cyberScore,
    isLoading,
    isError,
    sanitizedDomain: domain,
  } = useCyberScoreCombinedQuery();

  const [searchParams] = useSearchParams();

  const { branding } = useBranding();

  const [showScrollButton, setShowScrollButton] = useState(false);
  // const [roadmap, setRoadmap] = useState(false);

  const cyberCenterContent = {
    BDV: DarkwebEmailBreachesInfo,
    ESS: EmailSpoofingProtectionInfo,
    SEG: SecureEmailGatewayInfo,
    ISH: WebsiteSecuritySettingsInfo,
    PS: WordPressPluginSecurityInfo,
    WI: WebsiteEncryptionInfo,
    WAF: WebsiteFirewallInfo,
  };

  const scoreValues = {
    ESSScore: cyberScore?.ESSScore,
    ESSDetail: cyberScore?.ESS,
    BDVScore: cyberScore?.BDVScore,
    BDVDetail: cyberScore?.BDV,
    SEGScore: cyberScore?.SEGScore,
    SEGDetail: cyberScore?.SEG,
    ISHScore: cyberScore?.ISHScore,
    ISHDetail: cyberScore?.ISH,
    PSScore: cyberScore?.PSScore,
    PSDetail: cyberScore?.PS,
    WIScore: cyberScore?.WIScore,
    WIDetail: cyberScore?.WI,
    WAFScore: cyberScore?.WAFScore,
    WAFDetail: cyberScore?.WAF,
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollButton && window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else if (showScrollButton && window.pageYOffset <= 300) {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScrollButton]);

  const { brandingId } = useBranding();

  const { handleAnswer, setCyberScoreContext, roadmap, handleRoadmap } =
    useData();

  // const subscribeModalRef = React.useRef<HTMLDialogElement>(null);

  // const [subForm, setSubForm] = useState({
  //   email: "",
  //   firstName: "",
  //   lastName: "",
  //   companyName: "",
  //   companySite: "",
  // });

  // const { mutateAsync: subscribe_mutateAsync, isPending: subscribing } =
  //   useMutation({
  //     mutationKey: ["subscribe"],
  //     mutationFn: async (input: {
  //       email: string;
  //       firstName: string;
  //       lastName: string;
  //       companyName: string;
  //       companySite: string;
  //     }) => await mailchimpSubscribe({ ...input, msp: branding.mspName }),
  //     onMutate: (input) => {
  //       const { email, ...rest } = input;
  //       identifyUser(email, rest);
  //     },
  //     onError: (error) => {
  //       toast.error(error.message);
  //     },
  //     onSuccess: () => {
  //       subscribeModalRef.current?.close();
  //     },
  //     throwOnError: false,
  //   });

  const { detailsHidden } = useViewAccess();

  const modalRef = React.useRef<HTMLDialogElement>(null);
  const { openModal } = useCallToActionContext();

  if (isError) {
    return (
      <>
        <Navigate to={`/?${searchParams.toString()}`} />
      </>
    );
  }

  if (isLoading) {
    return (
      <Flex
        width={"100%"}
        height={"100dvh"}
        alignItems={"center"}
        justifyContent={"center"}
        backgroundColor={branding?.primaryColor}
      >
        <Flex
          shrink={1}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
          gap={"medium"}
          padding={"xl"}
        >
          <Image
            alt="Company Logo"
            src={branding?.yourLogo ?? logo}
            height={"150px"}
            maxWidth={"300px"}
            objectFit={"contain"}
          />
          <Text fontSize={"large"} color={branding?.fontColor}>
            We're calculating <b>{domain}</b>'s{" "}
            {branding.pdfTitle ?? "Cyber Score"}. <br />
            Sit tight, this can take 60 seconds.
          </Text>
          <Loader
            size="large"
            variation="linear"
            filledColor={branding.accentColor}
          />
        </Flex>
      </Flex>
    );
  }

  const resetAnswers = () => {
    handleAnswer("employeesCount", "");
    handleAnswer("annualRevenue", "");
    handleAnswer("domain", "");
    handleAnswer("sessionId", "");
    handleAnswer("industry", "");
    handleAnswer("mfaBankAccount", false);
    handleAnswer("mfaEmailAccounts", false);
    handleAnswer("conductingBackups", false);
    handleAnswer("updatingDevices", false);
    handleAnswer("itHandler", "");
    handleAnswer("itBudgetImportance", "");
    handleAnswer("email", "");
    handleRoadmap(false);
  };

  // const openCyberRoadmap = () => {
  //   let id = generateSessionId();
  //   handleAnswer("dateAdded", new Date().toISOString());
  //   handleAnswer("sessionId", id);
  //   handleAnswer("domain", domain);
  //   handleRoadmap(true);
  // };

  // Add the cyberscore to the global context
  setCyberScoreContext(cyberScore);

  if (!cyberScore) return null;

  /* ****************************************************************** */
  /* *********************** RETURN STATEMENT ************************* */
  return (
    <>
      {/* <Modal
        ref={subscribeModalRef}
        header={
          <Flex alignItems={"center"} gap={"large"}>
            <FaBell size={"40px"} color="gold" />
            <Heading level={5} color={"white"} textAlign={"start"}>
              <b>
                Stay on top of your {branding.pdfTitle ?? "Cyber Score"} with
                monthly updates sent to your inbox.
              </b>
            </Heading>
          </Flex>
        }
        size="medium"
      >
        <Flex
          as="form"
          direction={"column"}
          data-attr="subscribe-form"
          data-ph-capture-attribute-domain={domain}
          data-ph-capture-attribute-email={subForm.email}
          data-ph-capture-attribute-firstname={subForm.firstName}
          data-ph-capture-attribute-lastname={subForm.lastName}
          data-ph-capture-attribute-company={subForm.companyName}
          data-ph-capture-attribute-site={subForm.companySite}
          onSubmit={(e) => {
            e.preventDefault();
            // get the form data
            const formData = new FormData(e.target as HTMLFormElement);
            const data = Object.fromEntries(formData.entries());
            subscribe_mutateAsync(
              data as {
                email: string;
                firstName: string;
                lastName: string;
                companyName: string;
                companySite: string;
              }
            );
          }}
        >
          <Flex gap={"xs"} direction={{ base: "column", medium: "row" }}>
            <TextField
              isRequired
              flex={1}
              label={<b>First Name</b>}
              id="first-name"
              name="firstName"
              value={subForm.firstName}
              onChange={(e) =>
                setSubForm((prev) => ({ ...prev, firstName: e.target.value }))
              }
            ></TextField>
            <TextField
              isRequired
              flex={1}
              label={<b>Last Name</b>}
              id="last-name"
              name="lastName"
              value={subForm.lastName}
              onChange={(e) =>
                setSubForm((prev) => ({ ...prev, lastName: e.target.value }))
              }
            ></TextField>
            <TextField
              isRequired
              flex={2}
              label={<b>Email</b>}
              id="email"
              name="email"
              type="email"
              value={subForm.email}
              onChange={(e) =>
                setSubForm((prev) => ({ ...prev, email: e.target.value }))
              }
            ></TextField>
          </Flex>
          <Flex gap={"xs"} direction={{ base: "column", medium: "row" }}>
            <TextField
              flex={1}
              isRequired
              label={<b>Company Name</b>}
              id="company-name"
              name="companyName"
              value={subForm.companyName}
              onChange={(e) =>
                setSubForm((prev) => ({
                  ...prev,
                  companyName: e.target.value,
                }))
              }
            ></TextField>
            <TextField
              flex={1}
              isRequired
              label={<b>Company Site</b>}
              id="company-site"
              name="companySite"
              value={subForm.companySite}
              onChange={(e) =>
                setSubForm((prev) => ({
                  ...prev,
                  companySite: e.target.value,
                }))
              }
            ></TextField>
          </Flex>
          <Button
            variation="primary"
            type="submit"
            isLoading={subscribing}
            backgroundColor={branding.accentColor}
          >
            Subscribe
          </Button>
        </Flex>
      </Modal> */}
      <Modal
        ref={modalRef}
        header={
          <Flex alignItems={"center"}>
            <FaMedal color="white" size={"50px"} />
            <Heading level={4} color={"white"}>
              Claim Your Score!
            </Heading>
          </Flex>
        }
      >
        <ClaimScoreView domain={domain} />
      </Modal>
      <Flex
        direction={"column"}
        gap="0"
        alignItems={"center"}
        width={"100%"}
        className="page"
      >
        {showScrollButton && (
          <Flex
            display={{ base: "flex" }}
            position="fixed"
            left="1rem"
            bottom={{ base: "1rem", large: "1rem" }}
            width={"3rem"}
            height={"3rem"}
            borderRadius={"50%"}
            backgroundColor={"#0B101B"}
            alignItems="center"
            justifyContent="center"
            style={{
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              zIndex: 100,
            }}
            className="return-to-top-button"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <Text fontWeight={"bold"} color={"#FFF"} fontSize={"xxl"}>
              <BiArrowToTop />
            </Text>
          </Flex>
        )}
        {/* ================ NAV BAR ================ */}
        <Flex
          padding={"medium large medium large"}
          justifyContent={"center"}
          backgroundColor={branding?.primaryColor ?? "#0B101B"}
          alignItems={"center"}
          width={"100%"}
          style={{ zIndex: 999999 }}
          position={{ base: "static", large: "sticky" }}
          top={"0"}
          className="navbar"
        >
          <Flex
            width={"100%"}
            maxWidth={"1200px"}
            justifyContent={{ base: "center", large: "space-between" }}
            direction={{ base: "column", large: "row" }}
            gap={{ base: "xs", large: "0" }}
          >
            <Flex justifyContent={"center"} alignItems={"start"}>
              <Link
                flex={1}
                href={branding.mspUrl ?? "https://www.thecyberscore.com"}
                isExternal={!!branding?.mspUrl}
                data-attr="click-logo"
                data-ph-capture-attribute-mspname={branding.mspName}
                data-ph-capture-attribute-mspurl={branding.mspUrl}
                data-ph-capture-attribute-mpsid={branding?.memberId}
              >
                <Image
                  alt="Comapny logo"
                  src={branding?.yourLogo ?? logo}
                  height={"65px"}
                  maxWidth={"200px"}
                  objectFit={"contain"}
                />
              </Link>
            </Flex>

            <Flex width={{ base: "100%", large: "400px" }}>
              <EnterDomainField />
            </Flex>
          </Flex>
        </Flex>
        {/* ================ HEADER ================ */}

        <Flex
          padding={{ base: "medium large 0 large", large: "medium xl 0 xl" }}
          backgroundColor={branding?.primaryColor ?? "#0B101B"}
          width={"100%"}
          justifyContent={"center"}
        >
          <Flex
            borderRadius={"large large 0px 0px"}
            backgroundColor={"white"}
            width={"100%"}
            maxWidth={{ base: "100%", large: "1080px" }}
            // paddingRight={"xl"}
            paddingBottom={"medium"}
            // alignItems={{ base: "center", large: undefined }}
            direction={{ base: "column", large: "row" }}
            position={"relative"}
          >
            {/* <Flex position={"absolute"} top={"xxs"} right={"xxs"}>
              <Button
                variation="link"
                padding={"xs"}
                style={{
                  zIndex: 1000,
                  cursor: approvedClaim ? "default" : "pointer",
                }}
                gap={"xs"}
                isDisabled={approvedClaim !== undefined}
                backgroundColor={approvedClaim ? "#00B050" : "transparent"}
                borderRadius={"medium"}
                // border={approvedClaim ? "2px solid" : "none"}
                // borderColor={approvedClaim ? "#00B050" : "transparent"}
                // backgroundImage={
                //   "linear-gradient(120deg,   #ffffff22 45%, transparent 45%, transparent 65%, #ffffff22 65%, #ffffff22 70%, transparent 70%, transparent 74%, #ffffff22 74%, #ffffff22 77%, transparent 77%, transparent 100%)"
                // }
                color={approvedClaim ? "white" : "#00B050"}
                data-attr="claim-score"
                data-ph-capture-attribute-domain={domain}
                data-ph-capture-attribute-mspname={branding.mspName ?? "None"}
                data-ph-capture-attribute-mpsid={branding?.memberId}
                onClick={() => {
                  modalRef.current?.showModal();
                }}
              >
                <Flex
                  height={"25px"}
                  width={"25px"}
                  borderRadius={"100px"}
                  backgroundColor={approvedClaim ? "white" : "green.60"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {approvedClaim ? (
                    <FaCheck color="#00B050" size={"60%"} />
                  ) : (
                    <FaMedal color="white" size={"60%"} />
                  )}
                   <FaCheck color="white" size={"60%"} /> 
                </Flex>
                {approvedClaim ? "Claimed" : "Claim Your Score"}
              </Button>
              {/* <Button
                variation="link"
                borderRadius={"50px"}
                padding={"xs"}
                style={{ aspectRatio: "1", zIndex: 1000 }}
                data-attr="notification-bell"
                data-ph-capture-attribute-domain={domain}
                data-ph-capture-attribute-mspname={branding.mspName ?? "None"}
                data-ph-capture-attribute-mpsid={branding?.memberId}
                onClick={() => {
                  subscribeModalRef.current?.showModal();
                }}
              >
                <FaBell
                  size={"30px"}
                  color="gold"
                  style={{
                    filter:
                      "drop-shadow(-1px 1px 0 rgba(0, 0, 0)) drop-shadow(1px 1px 0 rgba(0, 0, 0)) drop-shadow(1px -1px 0 rgba(0, 0, 0)) drop-shadow(-1px -1px 0 rgba(0, 0, 0))",
                  }}
                />
              </Button> 
            </Flex> */}
            <CompanyInformation domain={domain} cyberScore={cyberScore} />
            <Flex
              flex={1}
              direction={{ base: "column", large: "row" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <ProfilePictures cyberScore={cyberScore} />
              <Flex
                direction={"column"}
                alignItems={{ base: "center", large: "start" }}
                gap={"5px"}
              >
                <Heading
                  textAlign={{ base: "center", large: "start" }}
                  fontWeight={"black"}
                  fontSize={"128px"}
                  color={"#FF343A"}
                  lineHeight={".9"}
                >
                  {cyberScore.BreachList.length}
                </Heading>
                <Text fontSize="32px" lineHeight={".9"}>
                  Dark Web
                </Text>
                <Text
                  fontWeight={"bold"}
                  fontSize="32px"
                  lineHeight={".9"}
                  color={"#FF343A"}
                >
                  Breaches
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <CyberCenterCard cyberScore={cyberScore} />
        <ViewIPCard />
        <Flex
          direction={"column"}
          gap={"large"}
          width={"100%"}
          maxWidth={{ base: "100%", large: "1080px" }}
          padding={"large small small small"}
        >
          {Object.entries(cyberCenterContent)
            .slice(0, 1)
            .map(([key, Component]) => (
              <View id={key + "-card"} key={key}>
                <Component values={scoreValues} cyberScore={cyberScore} />
              </View>
            ))}
        </Flex>
        {brandingId === null ? (
          <Marquee>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              gap={"xl"}
              direction={"row"}
            >
              <Image
                className="map-pin-icon"
                alt="map pin icon"
                src="/images/map-pin.png"
                height={"80px"}
              ></Image>
              <Flex direction={{ base: "column", large: "row" }}>
                <Text
                  textAlign={"left"}
                  fontSize={{ base: "medium", large: "xl" }}
                  width={{ base: "225px", large: "350px" }}
                  fontWeight={"bold"}
                  color={"white"}
                  style={{
                    textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  Need 1-on-1 help? <br />
                  Find a trusted IT provider
                </Text>
                <Button
                  className="cta-button"
                  color={branding?.accentColor || "#144EE3"}
                  borderRadius={"medium"}
                  backgroundColor="white"
                  fontSize={"large"}
                  data-attr="find-msp"
                  data-ph-capture-attribute-domain={domain}
                  onClick={() => {
                    window.open(
                      "https://www.thecyberscore.com/find-an-msp",
                      "_blank"
                    );
                  }}
                >
                  📍 Find an IT Pro
                </Button>
              </Flex>
            </Flex>
          </Marquee>
        ) : null}
        <View position={"relative"}>
          <View
            className={detailsHidden ? "blur" : ""}
            onClick={(e) => {
              e.stopPropagation();
              if (detailsHidden) openModal("score-details-blur");
            }}
          >
            <Flex
              direction={"column"}
              gap={"large"}
              maxWidth={{ base: "100%", large: "1080px" }}
              padding={"large small small small"}
            >
              {Object.entries(cyberCenterContent)
                .slice(1)
                .map(([key, Component]) => (
                  <View id={key + "-card"} key={key}>
                    <Component values={scoreValues} cyberScore={cyberScore} />
                  </View>
                ))}
            </Flex>

            <>
              {roadmap && (
                <Flex
                  position="fixed" // Use 'fixed' to cover the entire viewport
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  backgroundColor={{
                    base: "transparent",
                    large: "rgba(255, 255, 255, 0.5)",
                  }} // Base for mobile (full-screen modal doesn't need overlay), large for desktop
                  justifyContent="center"
                  alignItems="center"
                  style={{ zIndex: 99 }} // Ensures the overlay is above the content
                >
                  <View
                    as="dialog"
                    open={roadmap}
                    backgroundColor="white"
                    position="relative"
                    style={{ zIndex: 100 }}
                    padding={"0"}
                    border={"none"}
                    borderRadius={{ base: "0", large: "6%" }} // Full screen for mobile, rounded corners for desktop
                    width={{ base: "100vw", large: "auto" }} // Full width on mobile, auto on desktop
                    height={{ base: "100vh", large: "auto" }} // Full height on mobile, auto on desktop
                    maxWidth={{ base: "100vw", large: "600px" }} // Ensure the modal isn't wider than 600px on large screens
                    maxHeight={{ base: "100vh", large: "90vh" }} // Ensure the modal isn't taller than 90vh on large screens
                    overflow="hidden"
                    boxShadow={{
                      base: "",
                      large: "0 0 5px 5px rgba(0,0,0,0.2)",
                    }} // Optional shadow for better visibility on desktop
                  >
                    <>
                      <P1 />
                    </>
                    <Button
                      onClick={() => resetAnswers()}
                      border={"1px solid #144EE3"}
                      position={"absolute"}
                      top={"20px"}
                      right={"20px"}
                      color={"white"}
                      padding={"6px"}
                    >
                      X
                    </Button>
                  </View>
                </Flex>
              )}
            </>
          </View>
          {detailsHidden ? (
            <Flex
              position={"absolute"}
              top={"50dvh"}
              width={"100%"}
              justifyContent={"center"}
            >
              <Button
                variation="primary"
                backgroundColor={branding.primaryColor}
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal("score-details");
                }}
              >
                Unlock your full Score Card
              </Button>
            </Flex>
          ) : null}
        </View>
      </Flex>
    </>
  );
}
